<template>
  <div class="container">
    <div class="title">
      <!-- <el-image
        :src="require('../../../assets/image/login-tit.png')"
      ></el-image> -->
			初中学生综合素质评价电子平台
    </div>
    <div class="logo-box">
      <h3><span class="go-back"><el-button type="text"
		    @click="goBack"
			class="go-back"
			icon="el-icon-back"
			size="mini">返回</el-button></span>忘记密码</h3>
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
		v-show="!showJumpTips"
      >
        <el-form-item label="手机号" :label-width="labelW" prop="phoneNumber">
          <el-input
            v-model.trim="form.phoneNumber"
            prefix-icon="el-icon-user"
			placeholder="请输入手机号"
			oninput="value = value.replace(/[^\d]/g,'')"
			:style="formItemW"
			maxlength="11"
          ></el-input>
		  <el-button
		    type="text"
		    @click="getSms"
			class="get-sms"
			size="mini"
			ref="getSmsBtn"
			:disabled="disableSmsBtn"
		    >获取验证码</el-button>
        </el-form-item>
		<el-form-item label="验证码" :label-width="labelW" prop="smsYzm">
		  <el-input
		    v-model.trim="form.smsYzm"
		    prefix-icon="el-icon-message"
			placeholder="请输入验证码"
			oninput="value = value.replace(/[^\d]/g,'')"
			:style="formItemW"
			ref="checkCode"
			maxlength="6"
		  ></el-input>
		</el-form-item>
		<el-form-item label="身份选择" :label-width="labelW" prop="isStudent">
		  <el-select :style="formItemW" v-model="form.isStudent" placeholder="身份选择">
		      <el-option key="0" label="家长" value="0"></el-option>
			  <el-option key="1" label="学生" value="1"></el-option>
		    </el-select>
		</el-form-item>
		<el-form-item label="学籍号" :label-width="labelW" v-if="form.isStudent == 1" prop="loginName">
		  <el-input
		    v-model.trim="form.loginName"
			placeholder="请输入学生的学籍号"
		    prefix-icon="el-icon-user"
			:style="formItemW"
		  ></el-input>
		</el-form-item>
        <el-form-item label="新密码" :label-width="labelW" prop="newPwd">
          <el-input
            v-model="form.newPwd"
            prefix-icon="el-icon-lock"
			placeholder="请输入新密码"
            show-password
			:style="formItemW"
          ></el-input>
        </el-form-item>
		<el-form-item label="确认新密码" :label-width="labelW" prop="confirmPwd">
		  <el-input
		    v-model="form.confirmPwd"
		    prefix-icon="el-icon-lock"
			placeholder="再次输入新密码"
		    show-password
		    :style="formItemW"
		  ></el-input>
		</el-form-item>
      </el-form>
	  <div style="color: red;text-align: center; margin: 15px auto">其他账号用户忘记密码时，请联系上级管理员重置密码</div>
      <el-button
        type="primary"
        @click="submitForm"
        style="width: 100%"
        :loading="loading"
		 v-show="!showJumpTips"
        >确&nbsp;&nbsp;定</el-button>
		<div class="jump-tips-box" v-show="showJumpTips">
			<p class="jump-tips">密码修改成功，<span class="jump-remain-sec" ref="remainSec">{{countdown.jump}}</span>秒后跳转到登录页面</p>
		</div>
    </div>
    <div class="footer">
      <div class="footer-content">
        <!-- <div class="footer-center">
          <p>版权所有：秦皇岛市教育局</p>
          <p>地址：河北省秦皇岛市海港区秦皇东大街485号</p>
		  <p><a class="beian" href="https://beian.miit.gov.cn" target="_blank">冀ICP备05030247号-7</a></p>
        </div> -->
        <div class="footer-center">
          <p>违法和不良信息举报电话：0311-86036653</p>
          <p>E-mail：qhdedu@yeah.net</p>
          <p>技术支持：师达教育</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forgetPwd, getSmsCheckCode } from "@/api/public/login"
export default {
  data() {
	var validPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("密码不能为空"));
      } else {
        // let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,12}$/;
        let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8}$/
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("密码为8位大小写字母和数字组合"));
        }
      }
    };
    var validSurePass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("密码不能为空"));
      } else {
        // let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,12}$/;
        let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8}$/
        if (reg.test(value)) {
          if (this.form.newPwd) {
            if (value == this.form.newPwd) {
              callback();
            } else {
              return callback(new Error("两次输入的密码不一致"));
            }
          }
        } else {
          return callback(new Error("密码为8位大小写字母和数字组合"));
        }
      }
    };  
    return {
      loading: false,
	  countdown: {
		  jump: 3,
		  sms: 300
	  },
	  disableSmsBtn: true,
	  timer: {
		  jump: 0,
		  sms: 0
	  },
	  labelW: '100px',
	  formItemW: {
		width: '220px'  
	  },
	  showJumpTips: false,
      form: {
        phoneNumber: "",
        smsYzm: "",
		loginName: "",
		newPwd: "",
		confirmPwd: "",
		isStudent: ''
      },
      rules: {
        phoneNumber: [{ required: true, message: "手机号格式不正确", trigger: "blur", validator: (rule, val, callback) => {
			return /^1[23456789]\d{9}$/.test(val) ? '' : callback(new Error("手机号格式不正确"))
		} }],
        smsYzm: [{ required: true, message: "验证码不能为空", trigger: "blur", validator: (rule, val, callback) => {
			return /^\d{6}$/.test(val) ? '' : callback(new Error("验证码格式不正确"))
		} }],
		isStudent: [{ required: true }],
		loginName: [{ required: true, message: "学籍号不能为空", trigger: "blur" }],
		newPwd: [{ required: true, validator: validPass, trigger: "blur" }],
		confirmPwd: [{ required: true, validator: validSurePass, trigger: "blur" }]
      },
    };
  },
  watch: {
	'form.phoneNumber'(newV) {
		newV = `${newV}`
		this.disableSmsBtn = true
		if (newV.length == 11) {
			this.disableSmsBtn = false
		}
	}
  },
  methods: {
	// 获取验证码
	getSms() {
		let that = this
		getSmsCheckCode({
			phoneNumber: this.form.phoneNumber
		}).then(() => {
			that.$refs.checkCode.focus()
			that.form.smsYzm = ''
			that.disableSmsBtn = true
			that.startCountdown(that.timer.sms, that.countdown.sms, that.$refs.getSmsBtn, 's后重新获取', () => {
				that.disableSmsBtn = false
				that.$refs.getSmsBtn.$el.innerText = '重新发送'
			})
		})
	},
	// 提交
    submitForm() {
		let equal = this.isEqualPwd()
		if (equal.flag) {
			let that = this
			forgetPwd(this.form).then(res => {
				this.$message({
				  message: '密码修改成功',
				  onClose () {
					that.showJumpTips = true
					that.startCountdown(that.timer.jump, that.countdown.jump, that.$refs.remainSec, '', () => {
						clearTimeout(that.timer.jump)
						clearTimeout(that.timer.sms)
						that.$router.push('/login')
					})
				  }
				})	  
			})
		} else {
			this.$message.warning(equal.msg)
		}
    },
	// 倒计时
	// 定时器，总秒数，递减变量，显示文字 计时完毕的callback
	startCountdown(timer, vari, ref, extraTxt, callback) {
		clearTimeout(timer)
		let that = this
		let el = ref.$el ? ref.$el : ref
		timer = setTimeout(function (){
			vari--
			el.innerText = `${vari}${extraTxt}`
			if (vari == 0) {
				clearTimeout(timer)
				callback && callback()
			} else {
				that.startCountdown(timer, vari, ref, extraTxt, callback)
			}
		}, 1000)
	},
	// 2个密码是否一致
	isEqualPwd() {
		let flag = this.form.newPwd === this.form.confirmPwd
		return {
			flag: flag,
			msg: flag ? '' : '两次输入密码不一致'
		}
	},
	// 返回
	goBack() {
		clearTimeout(this.timer.jump)
		clearTimeout(this.timer.sms)
		this.$router.go(-1)
	}
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
  position: relative;
  padding-top: 50px;
  box-sizing: border-box;
  background: url("../../../assets/image/login-bg.jpg") no-repeat center / 100% 100%;
}

.container .title {
  font-size: 42px;
  font-weight: bolder;
  color: #fff;
  margin-top: 42px;
  text-align: center;
  text-shadow: 0px 4px 6px rgba(0, 5, 121, 0.26);
}

.logo-box {
  width: 400px;
  padding: 20px 40px;
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}
.logo-box h3 {
	position: relative;
  color: #305893;
  text-align: center;
  margin-top: 0;
}
.logo-box h3 .go-back {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
	width: 50px;
}

.logo-box .line {
  border-bottom: 1px solid #e2e3e7;
  margin-bottom: 22px;
}
.get-sms {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(0, -50%);
	padding: 5px 10px;
}
.warn-msg {
  height: 16px;
  font-size: 12px;
  color: red;
}
.footer {
  width: 100%;
  height: 96px;
  background: rgba(28, 88, 139, 0.92);
  position: absolute;
  bottom: 0;
}
.footer-content{
    max-width: 1400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.footer-center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.footer-center p{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #ffffff;
    display: inline-block;
    margin: 0 15px;
    opacity: 0.5;
}
.jump-tips-box {
	padding: 20px 0;
	text-align: center;
	font-size: 14px;
	color: #909399;
}
.jump-remain-sec {
	color: #67C23A;
	margin-right: 5px;
}
</style>